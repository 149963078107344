var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.oneCompany)?_c('div',{staticClass:"main__container main__padding"},[_c('div',{staticClass:"row__start"},[_c('div',{staticClass:"backbutton pointer",on:{"click":function($event){$event.stopPropagation();return _vm.goToHome($event)}}},[_c('ArrowLeftDefaultSvg')],1),_c('h2',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.goToHome($event)}}},[_vm._v("Suche")])]),_c('div',{staticStyle:{"margin-top":"30px"}}),(_vm.oneCompany)?_c('h2',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.oneCompany.name))]):_vm._e(),(_vm.oneCompany)?_c('CompanyHeader'):_vm._e(),_c('div',{staticClass:"row__start company__row"},[_c('div',{staticClass:"row__start--flex1 image__row"},[(_vm.oneCompany)?_c('CompanyImages'):_vm._e()],1),_c('div',{staticClass:"row__start--flex3 row__flex3--w43"},[_c('div',{staticClass:"appointmentBox"},[_c('h1',[_vm._v("Termin vereinbaren")]),(_vm.oneCompany && _vm.oneCompany.hasWorkshops == true)?_c('p',{staticClass:"mt-15 mb-60",domProps:{"innerHTML":_vm._s(
                        'Um einen Termin mit uns zu buchen, stehen Dir an unterschiedlichen Tagen und zu unterschiedlichen Zeiträumen verschiedene Ansprechpartner zur Verfügung. Du kannst wählen, ob du per Telefon, Videomeeting, Chat oder auf der Messe mit uns kommunizieren möchtest. <br />Wir freuen uns auf deine Terminbuchung (15 Minuten pro Termin) und deine anschließende Kontaktaufnahme über die Daten, die Dir per E-Mail nach der Buchung mitgeteilt werden.'
                    )}}):_vm._e(),(
                        _vm.oneCompany.hasWorkshops == false &&
                            !_vm.oneCompany.is_fair_participant
                    )?_c('p',{staticClass:"mt-15 mb-60"},[_vm._v(" Bitte über unsere allgemeinen Kontaktdaten einen Termin vereinbaren. ")]):(
                        _vm.oneCompany.hasWorkshops == false &&
                            _vm.oneCompany.is_fair_participant
                    )?_c('p',{staticClass:"mt-15 mb-60"},[_vm._v(" Leider stehen zurzeit keine Termine mehr zur Verfügung. Bitte komme direkt zu unserem Stand auf der Veranstaltung. ")]):_vm._e(),(_vm.oneCompany.hasWorkshops == true)?_c('div',{staticClass:"absoluteBox absoluteBox--right"},[_c('a',{staticClass:"button button--big",class:_vm.token ? '' : 'button--grey',on:{"click":function($event){$event.stopPropagation();return _vm.openAppointmentModal({ id: _vm.id })}}},[_c('p',{staticClass:"text__white"},[_vm._v("Jetzt Termin buchen")])])]):_vm._e()])])]),(_vm.oneCompany)?_c('CompanyContent',{attrs:{"id":_vm.id}}):_vm._e(),_c('CompanyAppointmentModal',{attrs:{"company_id":_vm.id,"showModal":_vm.showAppointmentModal},on:{"close":function($event){_vm.showAppointmentModal = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }